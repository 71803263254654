<i18n>
{
  "de": {
		"emissionsIntensityTitle": "Emissionsintensität kg CO₂e/m²",
		"energyConsumptionTitle": "Energieverbrauch kWh/m²",
		"s12eTargetLabel": "Scope 1-2 Emissionen",
    "s123eTargetLabel": "Scope 1-3 Emissionen",
    "eeTargetLabel": "Endenergie",
    "peTargetLabel": "Primärenergie",
    "units": {
      "s12e": "kg CO₂e/m²",
      "s123e": "kg CO₂e/m²",
      "ee": "kWh/m²",
      "pe": "kWh/m²"
    }
  }
}    
</i18n>

<template>
  <Box class="targets-chart">
    <LineChart :chart-data="chartData" :options="options" />
  </Box>
</template>

<script>
import _ from 'lodash'

import Box from '@/components/cui/layout/Box.vue'
import LineChart from '@/components/shared/charts/LineChart.vue'

export default {
  components: {
    Box,
    LineChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  computed: {
    targets() {
      return this.portfolio.targets
    },

    years() {
      const targetsMaxYear = _.maxBy(this.targets, 'year')?.year || 1900
      const maxYear = Math.max(targetsMaxYear, this.portfolio.sim_end_year)

      return _.range(this.portfolio.sim_start_year, maxYear + 1)
    },

    colors() {
      return {
        s12e: '#EEA857',
        s123e: '#EB4646',
        ee: '#DD75CD',
        pe: '#373DE1',
      }
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        animation: false,
        scales: {
          y1: {
            type: 'linear',
            beginAtZero: true,
            position: 'left',
            title: {
              display: true,
              text: this.$t('emissionsIntensityTitle'),
            },
          },
          y2: {
            type: 'linear',
            beginAtZero: true,
            position: 'right',
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            title: {
              display: true,
              text: this.$t('energyConsumptionTitle'),
            },
          },
        },
        elements: {
          line: {
            borderWidth: 3,
            backgroundColor: 'white',
          },
          point: {
            pointRadius: 3,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (item) => {
                const datasetLabel = item.dataset.label
                const value = item.parsed.y
                return `${datasetLabel}: ${value} ${this.$t('units')[item.dataset.id]}`
              },
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            display: true,
          },
          annotation: {
            annotations: {
              range: {
                type: 'box',
                xMin: _.findIndex(this.years, (v) => v === this.portfolio.sim_end_year),
                xMax: _.findIndex(this.years, (v) => v === _.last(this.years)),
                yMin: 'start',
                yMax: 'end',
                yScaleID: 'y1',
                borderWidth: 0,
                backgroundColor: 'hsla(0, 0%, 75%, 18%)',
              },
            },
          },
        },
      }
    },

    chartData() {
      const getData = (key) =>
        this.years.map((year) => {
          const target = _.find(this.targets, { year: year })?.[key]

          return target >= 0 ? target : null
        })

      return {
        labels: this.years,
        datasets: [
          {
            id: 's12e',
            label: this.$t('s12eTargetLabel'),
            data: getData('s12e_total'),
            borderColor: this.colors.s12e,
            pointBackgroundColor: this.colors.s12e,
            yAxisID: 'y1',
          },
          {
            id: 's123e',
            label: this.$t('s123eTargetLabel'),
            data: getData('s123e_total'),
            borderColor: this.colors.s123e,
            pointBackgroundColor: this.colors.s123e,
            yAxisID: 'y1',
          },
          {
            id: 'ee',
            label: this.$t('eeTargetLabel'),
            data: getData('ee_total'),
            borderColor: this.colors.ee,
            pointBackgroundColor: this.colors.ee,
            yAxisID: 'y2',
          },
          {
            id: 'pe',
            label: this.$t('peTargetLabel'),
            data: getData('pe_total'),
            borderColor: this.colors.pe,
            pointBackgroundColor: this.colors.pe,
            yAxisID: 'y2',
          },
        ],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.targets-chart {
  padding-bottom: var(--spacing-xl);
}
</style>
